import EventBus from "./EventBus"

export class Component {
	constructor(target) {
		this.DOM = {}
		this.DOM.root = target
		this.DOM.body = document.body

		this.eventBus = new EventBus()
	}

	unmount() {}
}
