import { Footer } from "./Footer"
import { Header } from "./Header"
import { Intro } from "./Intro"
import { Presentation } from "./Presentation"
import { Presentation2 } from "./Presentation2"
import { Services } from "./Services"
import { Values } from "./Values"

export const componentsList = {
	Footer: Footer,
	Header: Header,
	Intro: Intro,
	Presentation: Presentation,
	Presentation2: Presentation2,
	Services: Services,
	Values: Values,
}
