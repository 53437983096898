import gsap from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

gsap.registerPlugin(ScrollToPlugin)

export const wrapElements = (elems, wrapType, wrapClass) => {
	elems.forEach((char) => {
		const wrapEl = document.createElement(wrapType)
		wrapEl.classList = wrapClass
		char.parentNode.appendChild(wrapEl)
		wrapEl.appendChild(char)
	})
}

export const scrollToTop = () => {
	return new Promise((resolve) => {
		gsap.to(window, {
			duration: 0,
			scrollTo: 0,
			onComplete: resolve,
		})
	})
}
