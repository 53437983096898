export const DEBUG_MODE = process.env.NODE_ENV == "development" ? true : false
export const showTimer = process.env.NODE_ENV == "development" ? true : false

//console.log(process.env.NODE_ENV)
//console.log(DEBUG_MODE)

export const DEBUG = {
	//scrollTriggerMarkers: DEBUG_MODE ? true : false,
	skipTransition: DEBUG_MODE ? true : false,
}

export const isSPA = false
