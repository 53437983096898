export default class LanguageManager {
	constructor() {
		this.currentLang = localStorage.getItem("language") || "fr"
		this.langData = {}
		this.allowedTags = ["p", "br", "strong", "em", "u", "ol", "ul", "li"] // Add or remove tags as needed
	}

	async init() {
		await this.initializeLanguage()
		this.setupLanguageSwitcher()
		//this.logTranslatableStrings()
	}

	updateContent() {
		const elements = document.querySelectorAll("[data-lang]")
		elements.forEach((element) => {
			const key = element.getAttribute("data-lang")
			if (
				this.langData[this.currentLang] &&
				this.langData[this.currentLang][key]
			) {
				const translation = this.langData[this.currentLang][key]
				if (element.tagName === "TITLE") {
					document.title = translation
				} else {
					element.innerHTML = this.sanitizeHTML(translation)
				}
			} else {
				console.warn(
					`Translation missing for key: ${key} in language: ${this.currentLang}`
				)
			}
		})
		this.updateLanguageSwitcherVisibility()
	}

	sanitizeHTML(str) {
		const parser = new DOMParser()
		const doc = parser.parseFromString(str, "text/html")
		const sanitized = this.sanitizeNode(doc.body)
		return sanitized.innerHTML
	}

	sanitizeNode(node) {
		const clone = node.cloneNode(false)

		if (node.nodeType === Node.TEXT_NODE) {
			clone.textContent = node.textContent
			return clone
		}

		if (
			node.nodeType === Node.ELEMENT_NODE &&
			this.allowedTags.includes(node.tagName.toLowerCase())
		) {
			for (const child of node.childNodes) {
				clone.appendChild(this.sanitizeNode(child))
			}
		} else {
			for (const child of node.childNodes) {
				clone.appendChild(this.sanitizeNode(child))
			}
		}

		return clone
	}

	setLanguageData(langCode, data) {
		this.langData[langCode] = data
	}

	setLanguagePreference(lang) {
		localStorage.setItem("language", lang)
	}

	async initializeLanguage() {
		const storedLang = localStorage.getItem("language") || this.currentLang
		if (storedLang !== "en" && storedLang !== "fr") {
			console.warn(
				`Unsupported stored language: ${storedLang}. Falling back to English.`
			)
			this.setLanguagePreference("en")
			location.reload()
			return
		}

		try {
			const data = await this.fetchLanguageData(storedLang)
			this.setLanguageData(storedLang, data)
			this.currentLang = storedLang
			this.updateContent()
		} catch (error) {
			console.error(`Failed to initialize language ${storedLang}:`, error)
			if (storedLang !== "en") {
				this.setLanguagePreference("en")
				location.reload()
			}
		}
	}

	async fetchLanguageData(lang) {
		const url = `./lang/${lang}.json`
		try {
			const response = await fetch(url)
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`)
			}
			const data = await response.json()
			return data
		} catch (error) {
			console.error(`Error fetching ${url}:`, error)
			throw error
		}
	}

	setupLanguageSwitcher() {
		const languageSwitcher = document.getElementById("language-selector")
		if (languageSwitcher) {
			const links = languageSwitcher.getElementsByTagName("a")
			Array.from(links).forEach((link) => {
				link.addEventListener("click", (event) => {
					event.preventDefault()
					const lang = link.getAttribute("data-lang")
					if (lang !== this.currentLang) {
						this.setLanguagePreference(lang)
						location.reload()
					}
				})
			})
			this.updateLanguageSwitcherVisibility()
		} else {
			console.warn("Language selector element not found")
		}
	}

	updateLanguageSwitcherVisibility() {
		const languageSwitcher = document.getElementById("language-selector")
		if (languageSwitcher) {
			const links = languageSwitcher.getElementsByTagName("a")
			Array.from(links).forEach((link) => {
				const lang = link.getAttribute("data-lang")
				if (lang === this.currentLang) {
					link.style.display = "none"
				} else {
					link.style.display = ""
				}
			})
		}
	}

	parsePageForTranslations() {
		const elements = document.querySelectorAll("[data-lang]")
		const translations = {}

		elements.forEach((element) => {
			const key = element.getAttribute("data-lang")
			translations[key] = ""
		})

		return translations
	}

	async updateLanguageFile(lang) {
		const url = `./lang/${lang}.json`
		let existingTranslations = {}

		try {
			const response = await fetch(url)
			if (response.ok) {
				existingTranslations = await response.json()
			}
		} catch (error) {
			console.warn(
				`No existing language file found for ${lang}. Creating a new one.`
			)
		}

		const pageTranslations = this.parsePageForTranslations()
		const updatedTranslations = { ...pageTranslations, ...existingTranslations }

		return updatedTranslations
	}

	generateEmptyLanguageFile(lang) {
		const translations = this.parsePageForTranslations()
		return translations
	}

	logTranslatableStrings() {
		const elements = document.querySelectorAll("[data-lang]")
		const translations = {}

		elements.forEach((element) => {
			const key = element.getAttribute("data-lang")
			translations[key] = this.langData[this.currentLang]?.[key] || ""
		})

		console.log("Translatable strings found on the page:")
		console.log(JSON.stringify(translations, null, 2))
	}

	static changeLanguage(lang) {
		const languageManager = new LanguageManager()
		languageManager.setLanguagePreference(lang)
		location.reload()
	}
}
