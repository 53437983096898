import "../styles/style.scss"
import gsap from "gsap"
import EventBus from "./abstracts/EventBus"
import Scroller from "./managers/Scroller"
import { Parallax } from "./presets/Parallax"
import ComponentsManager from "./managers/ComponentsManager"
import TransitionManager from "./managers/TransitionManager"
import LanguageManager from "./managers/LanguageManager"
import { createBuildTimeOverlay } from "./utils/buildTimeOverlay"
import { isSPA, DEBUG, showTimer } from "./config"

export default class App {
	static instance

	constructor() {
		if (App.instance) {
			return App.instance
		}
		App.instance = this

		this.initCache()
		this.preInit()
	}

	initCache() {}

	preInit() {
		if (history.scrollRestoration) {
			history.scrollRestoration = "manual"
		}

		window.addEventListener("load", () => {
			setTimeout(() => window.scrollTo(0, 0), 100)
		})
	}

	async init() {
		await this.initializeLanguage()
		await this.ensureFontsLoaded()
		this.initializeComponents()
	}

	async ensureFontsLoaded() {
		// Ensure fonts are loaded before running any font-dependent code
		await document.fonts.ready
		//console.log("Fonts are fully loaded")
	}

	async initializeLanguage() {
		this.languageManager = new LanguageManager()
		await this.languageManager.init()
	}

	initializeComponents() {
		this.eventBus = new EventBus()
		this.scroller = new Scroller()
		this.parallax = new Parallax()
		window.componentsManager = this.componentsManager = new ComponentsManager()

		if (isSPA) {
			this.transitionManager = new TransitionManager()
		}

		if (showTimer) {
			createBuildTimeOverlay()
		}
	}
}

// Initialize the app
document.addEventListener("DOMContentLoaded", async () => {
	//console.log("DOMContentLoaded event fired in App.js")
	const app = new App()
	await app.init()
	document.querySelector(".load-overlay").classList.remove("is-visible")
	document.querySelector(".load-overlay").classList.add("is-invisible")
})

// Set up global changeLanguage function
window.changeLanguage = LanguageManager.changeLanguage
