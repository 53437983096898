import { Component } from "../abstracts/Component"
import EventBus from "../abstracts/EventBus"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import SplitText from "gsap/SplitText"
import { wrapElements } from "../tools/DOM"

gsap.registerPlugin(ScrollTrigger, SplitText)

export class Services extends Component {
	constructor(config) {
		super(config)

		this.eventBus = new EventBus()
		this.initCache()
		this.init()
	}

	initCache() {
		this.DOM.services = this.DOM.root.querySelectorAll(".services__service")
		this.splitTexts = []
	}

	init() {
		this.initAnimation()
	}

	initAnimation() {
		this.DOM.services.forEach((service, index) => {
			const heading = service.querySelector(".services__heading")
			const subHeadingContainer = service.querySelector(".services__subheading")
			const subHeading = service.querySelector(".services__subheading span")
			const text = service.querySelectorAll(".services__text p")

			const headingSplit = new SplitText(heading, {
				type: "lines, words",
			})
			wrapElements(headingSplit.lines, "div", "line-wrap")

			const subHeadingSplit = new SplitText(subHeading, {
				type: "chars",
			})
			wrapElements(subHeadingSplit.lines, "div", "char-wrap")

			const textSplit = new SplitText(text, {
				type: "lines",
			})
			wrapElements(textSplit.lines, "div", "line-wrap")

			this.splitTexts.push(headingSplit, textSplit)

			gsap.set(headingSplit.words, { yPercent: 100 })
			gsap.set(subHeadingSplit.chars, { xPercent: -100, opacity: 0 })
			gsap.set(subHeadingContainer, { scaleX: 0, transformOrigin: "left" })
			gsap.set(textSplit.lines, { yPercent: -100 })

			this.animateService(
				service,
				textSplit.lines,
				headingSplit.words,
				subHeadingSplit.chars,
				subHeadingContainer,
				index
			)
		})

		this.changeHeaderColor()
	}

	animateService(
		service,
		textLines,
		headingWords,
		subHeadingChars,
		subHeadingContainer,
		index
	) {
		const tlReveal = gsap.timeline({
			scrollTrigger: {
				trigger: service,
				start: "top bottom-=100px",

				toggleActions: "play pause play reverse",
				id: `serviceAnimation${index}`,
			},
		})

		tlReveal
			.to(textLines, {
				yPercent: 0,
				stagger: 0.1,
				duration: 0.6,
			})
			.to(
				headingWords,
				{
					yPercent: 0,
					stagger: 0.1,
					duration: 0.6,
				},
				"-=0.8"
			)
			.to(
				subHeadingContainer,
				{
					scaleX: 1,
					duration: 0.6,
					ease: "expo.out",
				},
				"-=.3"
			)
			.to(
				subHeadingChars,
				{
					xPercent: 0,
					opacity: 1,
					stagger: 0.04,
					duration: 0.2,
				},
				"-=.3"
			)
	}

	changeHeaderColor() {
		ScrollTrigger.create({
			id: "changeHeaderColor",
			trigger: this.DOM.root,
			start: "top top+=60px",
			onEnter: () => {
				this.eventBus.emit("changeHeaderColorToAccent")
			},
			onLeaveBack: () => {
				this.eventBus.emit("changeHeaderColorToPrimary")
			},
			onEnterBack: () => {
				this.eventBus.emit("changeHeaderColorToAccent")
			},
			onLeave: () => {
				this.eventBus.emit("changeHeaderColorToPrimary")
			},
		})
	}

	resetAnimations() {
		// Kill all ScrollTrigger instances
		this.DOM.services.forEach((_, index) => {
			if (ScrollTrigger.getById(`serviceAnimation${index}`)) {
				ScrollTrigger.getById(`serviceAnimation${index}`).kill()
			}
			if (ScrollTrigger.getById(`changeHeaderColor`)) {
				ScrollTrigger.getById(`changeHeaderColor`).kill()
			}
		})

		// Revert all SplitText instances
		this.splitTexts.forEach((split) => split.revert())

		// Kill all tweens associated with the services
		gsap.killTweensOf(this.DOM.services)

		// Reset all properties of the elements
		gsap.set(this.DOM.services, { clearProps: "all" })

		// Clear the splitTexts array
		this.splitTexts = []
	}

	resize() {
		this.resetAnimations()
		this.initAnimation()
	}
}
