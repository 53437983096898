import { Component } from "../abstracts/Component"
import EventBus from "../abstracts/EventBus"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { wrapElements } from "../tools/DOM"
gsap.registerPlugin(ScrollTrigger)

export class Header extends Component {
	constructor(config) {
		super(config)
		this.eventBus = new EventBus()
		this.initCache()
		this.init()
	}

	initCache() {
		this.DOM.section = this.DOM.body.querySelector(".presentation2")
		this.DOM.logo = this.DOM.root.querySelector(".header__logo")
		this.DOM.tag = this.DOM.root.querySelector(".header__tag")
		this.DOM.grey = this.DOM.root.querySelectorAll(".fill--surface-primary")
		this.DOM.sectionServices = document.querySelector(".services")
	}

	init() {
		this.initAnimation()
		this.bindEvents()
	}

	bindEvents() {
		this.eventBus.on("changeHeaderColorToAccent", () => {
			this.changeColorToWhite()
		})
		this.eventBus.on("changeHeaderColorToPrimary", () => {
			this.changeColorToPrimary()
		})
	}

	initAnimation() {
		if (this.DOM.section) {
			gsap.set([this.DOM.logo, this.DOM.tag], { opacity: 0 })
			this.animation()
		}
	}

	changeColorToWhite() {
		gsap.to(this.DOM.grey, {
			fill: "var(--color-surface-neutral-light)",
		})
	}

	changeColorToPrimary() {
		gsap.to(this.DOM.grey, {
			fill: "var(--color-surface-primary)",
		})
	}

	animation() {
		this.tl = gsap.timeline()
		this.scrollTrigger = ScrollTrigger.create({
			trigger: this.DOM.section,
			start: "bottom-=25px top",
			animation: this.tl,
			toggleActions: "play none reset none",
		})

		this.tl
			.fromTo(
				this.DOM.logo,
				{
					opacity: 0,
					x: -50,
				},
				{
					opacity: 1,
					x: 0,
					duration: 1,
					ease: "expo.out",
				}
			)
			.to(
				this.DOM.tag,
				{
					opacity: 1,
					duration: 1,
				},
				"-=.5"
			)
			.fromTo(
				this.DOM.tag,
				{
					x: 50,
				},
				{
					x: 0,
					duration: 1,
					ease: "expo.out",
				},
				"<"
			)
	}

	resetScrollAnimations() {
		// Kill the ScrollTrigger instance
		if (this.scrollTrigger) {
			this.scrollTrigger.kill()
			this.scrollTrigger = null
		}

		// Kill the timeline
		if (this.tl) {
			this.tl.kill()
			this.tl = null
		}

		// Reset all relevant DOM elements to their original state
		const elementsToReset = [
			this.DOM.logo,
			this.DOM.tag,
			...this.DOM.grey,
		].filter(Boolean)

		gsap.set(elementsToReset, { clearProps: "all" })
	}

	resize() {
		this.resetScrollAnimations()
		this.initCache()
		this.init()
	}
}
