import { Component } from "../abstracts/Component"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import SplitText from "gsap/SplitText"
import { wrapElements } from "../tools/DOM"
gsap.registerPlugin(ScrollTrigger, SplitText)

export class Presentation2 extends Component {
	constructor(config) {
		super(config)

		this.initCache()
		this.init()
	}

	initCache() {
		this.DOM.tag = this.DOM.root.querySelector(".presentation2__tag")
		this.DOM.plus = this.DOM.tag.querySelector(".plus")
	}

	init() {
		this.initAnimation()

		this.startAnimation()
	}

	initAnimation() {
		this.DOM.tagSplit = new SplitText(this.DOM.tag, {
			type: "chars",
			reduceWhiteSpace: "false",
		})
		//wrapElements(this.DOM.tagSplit.chars, "div", "char-wrap")
		gsap.set(this.DOM.tagSplit.chars, { xPercent: 100 })
	}

	startAnimation() {
		this.tlScroll = gsap.timeline({ paused: true })

		ScrollTrigger.create({
			id: "presentationScrollAnimation",
			trigger: this.DOM.root,
			pin: true,
			scrub: true,
			start: "top top",
			end: `+=${window.innerHeight * 0.8}`,
			//markers: true,
			toggleActions: "play pause play reset",
			animation: this.tlScroll,
		})

		this.tlScroll
			.fromTo(
				this.DOM.tagSplit.chars,
				{
					xPercent: -50,
					opacity: 0,
				},
				{
					xPercent: 0,
					opacity: 1,
					stagger: 0.2,
					duration: 0.4,
				}
			)
			.to(this.DOM.plus, {
				rotate: 90,
				transformOrigin: "center 62%",
				duration: 2,
			})
			.to(this.DOM.tagSplit.chars, {
				delay: 2,
			})
	}

	resetScrollAnimation() {
		// Kill the ScrollTrigger instance
		if (ScrollTrigger.getById("presentationScrollAnimation")) {
			ScrollTrigger.getById("presentationScrollAnimation").kill()
		}

		// Kill all tweens associated with the elements
		const elementsToReset = [this.DOM.tag, this.DOM.plus]

		gsap.killTweensOf(elementsToReset)

		// Kill the timeline if it exists
		if (this.tlScroll) {
			this.tlScroll.kill()
		}

		// Reset all properties of the elements
		gsap.set(elementsToReset, { clearProps: "all" })
	}

	resize() {
		this.resetScrollAnimation()

		this.initAnimation()
		this.startAnimation()
	}
}
