/**
 * Parallax Animation
 *
 * HTML Usage:
 * <div data-anim="parallax" data-options="start:60,end:-60" data-trigger="#trigger-element">
 *   <!-- Content -->
 * </div>
 *
 * Attributes:
 * - data-anim="parallax": Applies parallax effect
 * - data-options="start:60,end:-60": Customize start/end positions (optional)
 * - data-trigger="#trigger-element": Set custom trigger element (optional)
 *
 * Note: Positive values move down, negative up. Adjust for desired intensity.
 */

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export class Parallax {
	static instance

	constructor() {
		if (Parallax.instance) {
			return Parallax.instance
		}
		Parallax.instance = this

		this.init()
	}

	init() {
		this.parallaxElements = document.querySelectorAll('[data-anim="parallax"]')
		this.setupParallaxEffects()
	}

	parseOptions(optionsString) {
		const options = {}
		optionsString.split(",").forEach((option) => {
			const [key, value] = option.split(":")
			options[key.trim()] = isNaN(parseFloat(value.trim()))
				? value.trim()
				: parseFloat(value.trim())
		})
		return options
	}

	setupParallaxEffects() {
		this.parallaxElements.forEach((item) => {
			let options = { start: 60, end: -60 }
			if (item.dataset.options) {
				options = { ...options, ...this.parseOptions(item.dataset.options) }
			}

			const trigger = item.dataset.trigger
				? document.querySelector(item.dataset.trigger)
				: item

			gsap
				.timeline({
					scrollTrigger: {
						trigger: trigger,
						start: "top bottom",
						end: "bottom top",
						ease: "linear",
						scrub: true,
					},
				})
				.fromTo(item, { y: options.start }, { y: options.end })
		})
	}

	// Call this method when you need to refresh parallax effects (e.g., after dynamic content changes)
	refresh() {
		ScrollTrigger.refresh()
	}

	// Call this method when you need to kill all parallax effects
	destroy() {
		ScrollTrigger.getAll().forEach((trigger) => trigger.kill())
	}
}
