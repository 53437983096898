import Lenis from "lenis"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { DEBUG } from "../config"
gsap.registerPlugin(ScrollTrigger)

export default class Scroller {
	static instance

	constructor() {
		if (Scroller.instance) {
			return Scroller.instance
		}
		Scroller.instance = this

		this.init()
	}

	init() {
		this.lenis = new Lenis({
			wrapper: window,
			content: document.documentElement,
			smoothWheel: true,
			duration: 1.6,
			touchMultiplier: 0,
		})

		this.lenis.on("scroll", () => {
			ScrollTrigger.update()
		})

		this.lenisRaf = (time) => {
			if (this.lenis) {
				this.lenis.raf(time * 1000)
			}
		}

		gsap.ticker.add(this.lenisRaf)

		gsap.ticker.lagSmoothing(0)

		this.setScrollTriggerConfig()
	}

	setScrollTriggerConfig() {
		ScrollTrigger.config({
			limitCallbacks: true,
			ignoreMobileResize: true,
		})
		ScrollTrigger.defaults({ markers: DEBUG.scrollTriggerMarkers })
	}

	start() {
		if (this.lenis) {
			this.lenis.start()
		}
	}

	stop() {
		if (this.lenis) {
			this.lenis.stop()
		}
	}

	destroy() {
		if (this.lenis) {
			this.lenis.destroy()
			this.lenis = null
		}
		gsap.ticker.remove(this.lenisRaf)
	}

	removeScrollTriggerEvents() {
		let triggers = ScrollTrigger.getAll()
		let triggersCount = triggers.length

		for (let i = 0; i < triggersCount; i++) {
			triggers[i].kill(true)
		}
	}

	initNavScroll(navElement) {
		this.navLenis = new Lenis({
			wrapper: navElement,
			content: navElement,
			smoothWheel: true,
			duration: 1.6,
			touchMultiplier: 0,
		})

		this.navLenisRaf = (time) => {
			if (this.navLenis) {
				this.navLenis.raf(time * 1000)
			}
		}

		gsap.ticker.add(this.navLenisRaf)
	}

	destroyNavScroll() {
		if (this.navLenis) {
			this.navLenis.destroy()
			this.navLenis = null
		}
		gsap.ticker.remove(this.navLenisRaf)
	}
}
