import { Component } from "../abstracts/Component"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import SplitText from "gsap/SplitText"
import { wrapElements } from "../tools/DOM"
gsap.registerPlugin(ScrollTrigger, SplitText)

export class Presentation extends Component {
	constructor(config) {
		super(config)

		this.initCache()
		this.init()
	}

	initCache() {
		this.DOM.headings = this.DOM.root.querySelectorAll(".presentation__heading")
		this.DOM.headingConseil = this.DOM.root.querySelector(
			".presentation__heading--conseil"
		)
		this.DOM.headingPlus = this.DOM.root.querySelector(
			".presentation__heading--plus"
		)
		this.DOM.headingComm = this.DOM.root.querySelector(
			".presentation__heading--comm"
		)
		this.DOM.headingConseilWidth = this.DOM.headingConseil.offsetWidth
		this.DOM.headingPlusWidth = this.DOM.headingPlus.offsetWidth
		this.DOM.headingCommWidth = this.DOM.headingComm.offsetWidth

		this.DOM.dot = this.DOM.root.querySelector(".presentation__dot")
		this.DOM.dotWidth = this.DOM.dot.offsetWidth
	}

	init() {
		this.initAnimation()

		this.startAnimation()
	}

	initAnimation() {
		this.DOM.headingConseilSplit = new SplitText(this.DOM.headingConseil, {
			type: "chars",
		})
		this.DOM.headingPlusSplit = new SplitText(this.DOM.headingPlus, {
			type: "chars",
		})
		this.DOM.headingCommSplit = new SplitText(this.DOM.headingComm, {
			type: "chars",
		})
		/* this.DOM.headingConseilSplit.chars.reverse()
		this.DOM.headingPlusSplit.chars.reverse()
		this.DOM.headingCommSplit.chars.reverse() */
		gsap.set(this.DOM.dot, { scale: 0 })
		gsap.set(this.DOM.headingPlus, { autoAlpha: 0 })
	}

	startAnimation() {
		this.tlScroll = gsap.timeline({ paused: true })

		ScrollTrigger.create({
			id: "presentationScrollAnimation",
			trigger: this.DOM.root,
			pin: true,
			scrub: true,
			start: "top top",
			end: `+=${window.innerHeight * 4}`,
			toggleActions: "play pause play reset",
			animation: this.tlScroll,
		})

		this.tlScroll
			.to(this.DOM.dot, {
				scale: 1,
				delay: 0.5,
				duration: 0.5,
			})
			.to(this.DOM.dot, {
				duration: 1,
			})
			.to(this.DOM.dot, {
				width: this.DOM.headingConseil.offsetWidth + 50 + "px",
				duration: 2,
				//ease: "expo.out",
			})
			//Enter Conseil
			.from(
				this.DOM.headingConseilSplit.chars,
				{
					opacity: 0,
					//rotationX: -90,
					//transformOrigin: "50% 50% -50",
					stagger: {
						each: 0.1,
						from: "start",
					},
				},
				"-=.5"
			)
			.to(
				this.DOM.headingConseilSplit.chars,
				{
					opacity: 0,
					//rotationX: 90,
					//transformOrigin: "50% 50% -50",
					delay: 1,
					stagger: {
						each: 0.1,
						from: "start",
					},
				},
				"-=.07"
			)
			/* .from(
				this.DOM.headingPlusSplit.chars,
				{
					opacity: 0,
					//rotationX: -90,
					//transformOrigin: "50% 50% -50",
					stagger: {
						amount: 0.7,
						from: "start",
					},
				},
				"-=.5"
			)
			.to(
				this.DOM.dot,
				{
					width: this.DOM.headingPlus.offsetWidth - 20 + "px",
					duration: 0.7,
					ease: "expo.inOut",
				},
				"<"
			)
			.to(
				this.DOM.headingPlusSplit.chars,
				{
					opacity: 0,
					rotationX: 90,
					transformOrigin: "50% 50% -50",
					delay: 1,
					stagger: {
						amount: 0.5,
						from: "start",
					},
				},
				"-=.07"
			) */
			.to(this.DOM.dot, {
				width: this.DOM.dotWidth,
				duration: 0.7,
				//ease: "expo.inOut",
				duration: 2,
			})
			.to(this.DOM.dot, {
				width: this.DOM.headingComm.offsetWidth + 50 + "px",
				duration: 3,
				//ease: "expo.inOut",
			})
			//Enter Communication
			.from(
				this.DOM.headingCommSplit.chars,
				{
					opacity: 0,
					//rotationX: -90,
					//transformOrigin: "50% 50% -50",
					stagger: {
						each: 0.1,
						from: "start",
					},
				},
				"-=.5"
			)
			.to(
				this.DOM.headingCommSplit.chars,
				{
					opacity: 0,
					//rotationX: 90,
					//transformOrigin: "50% 50% -50",
					delay: 1,
					stagger: {
						each: 0.1,
						from: "start",
					},
				},
				"-=.07"
			)
			.to(this.DOM.dot, {
				width: this.DOM.dotWidth,
				duration: 2,
				//ease: "expo.inOut",
			})
			.to(this.DOM.dot, {
				width: this.DOM.dotWidth,
				duration: 1,
				//ease: "expo.inOut",
			})
			.to(this.DOM.dot, {
				scale: 0,
				duration: 0.5,
			})
		/* .to(this.DOM.dot, {
				width: 0,
				ease: "expo.inOut",
			}) */
	}

	resetScrollAnimation() {
		// Kill the ScrollTrigger instance
		if (ScrollTrigger.getById("presentationScrollAnimation")) {
			ScrollTrigger.getById("presentationScrollAnimation").kill()
		}

		//Revert all SplitText
		const textSplittedToRevert = [
			this.DOM.headingConseilSplit,
			this.DOM.headingPlusSplit,
			this.DOM.headingCommSplit,
		]

		textSplittedToRevert.forEach((split) => split.revert())

		// Kill all tweens associated with the elements
		const elementsToReset = [
			this.DOM.headingConseil,
			this.DOM.headingPlus,
			this.DOM.headingComm,
			this.DOM.dot,
		]

		gsap.killTweensOf(elementsToReset)

		// Kill the timeline if it exists
		if (this.tlScroll) {
			this.tlScroll.kill()
		}

		// Reset all properties of the elements
		gsap.set(elementsToReset, { clearProps: "all" })
	}

	resize() {
		this.resetScrollAnimation()

		this.initAnimation()
		this.startAnimation()
	}
}
