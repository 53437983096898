import { Component } from "../abstracts/Component"
import Scroller from "../managers/Scroller"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import SplitText from "gsap/SplitText"
import CustomEase from "gsap/CustomEase"
import { wrapElements, scrollToTop } from "../tools/DOM"
gsap.registerPlugin(ScrollTrigger, SplitText)

export class Intro extends Component {
	constructor(config) {
		super(config)

		this.scroller = new Scroller()
		this.initCache()
		this.init()
	}

	initCache() {
		this.DOM.header = this.DOM.body.querySelector(".header")
		this.DOM.dot = this.DOM.root.querySelector(".intro__dot")
		this.DOM.dotWidth = getComputedStyle(this.DOM.body).getPropertyValue(
			"--dot-width"
		)
		this.DOM.dotWrapper = this.DOM.root.querySelector(".intro__dot_wrapper")

		this.DOM.heading = this.DOM.root.querySelector(".intro__heading_wrapper")
		this.DOM.headingText = this.DOM.root.querySelector(".intro__heading svg g")
		this.DOM.headingChars = this.DOM.root.querySelectorAll(
			".intro__heading svg g path"
		)
		this.DOM.headingDot = this.DOM.root.querySelector(".intro__heading .dot")
		this.DOM.arrow = this.DOM.root.querySelector(".intro__arrow")
		this.DOM.nextSection = this.DOM.root.querySelector(
			".intro__section + section"
		)

		this.DOM.windowHeight = window.innerHeight
		this.DOM.windowWidth = window.innerWidth

		this.DOM.sectionPresentation2 = document.querySelector(".presentation2")
	}

	init() {
		/* INIT */
		this.initAnimation()
		this.initScrollAnimation()

		/* START */
		this.startEntryAnimation()
		this.startArrowBouncing()
	}

	initAnimation() {
		this.scroller.stop()

		gsap.set(this.DOM.dot, {
			scaleX: 120,
			scaleY: 120,
			transformOrigin: "center center",
		})

		gsap.set(this.DOM.heading, {
			bottom: "-100%",
		})

		gsap.set(this.DOM.arrow, { opacity: 0 })
	}

	startEntryAnimation() {
		let hasReachedEnd = false

		this.entryTimeline = gsap.timeline({
			defaults: {
				ease: "expo.out",
			},
			onComplete: () => {
				this.scroller.start()
			},
		})

		this.entryTimeline
			.to(this.DOM.root, {
				opacity: 1,
				duration: 0.8,
			})
			.to(this.DOM.dot, {
				scaleX: 100,
				scaleY: 1,
				transformOrigin: "center center",
				duration: 1.2,
			})
			.to(
				this.DOM.dot,
				{
					scaleX: 1,
					scaleY: 1,
					transformOrigin: "center center",
					duration: 1.2,
				},
				"-=.1"
			)
			.to(
				this.DOM.heading,
				{
					bottom: `50%`,
					duration: 2,
				},
				"-=.4"
			)
			.to(this.DOM.arrow, {
				opacity: 1,
				duration: 0.8,
				ease: "linear",
			})
	}

	startArrowBouncing() {
		gsap.to(this.DOM.arrow, {
			y: -20,
			duration: 1.6,
			repeat: -1,
			yoyo: true,
			ease: "sine.inOut",
			scrollTrigger: {
				trigger: this.DOM.root,
				start: "top bottom",
				toggleActions: "play pause play pause",
			},
		})
	}

	initScrollAnimation() {
		this.scrollTimeline = gsap.timeline()

		ScrollTrigger.create({
			id: "introScrollAnimation",
			trigger: this.DOM.root,
			start: "top top",
			end: this.DOM.nextSection,
			animation: this.scrollTimeline,
			scrub: true,
			pin: this.DOM.root,
		})

		this.scrollTimeline
			.to(this.DOM.dotWrapper, {
				width: "100%",
				height: "100%",
				transformOrigin: "center center",
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
				x: 0,
				y: 0,
				ease: "linear",
			})
			.to(this.DOM.sectionPresentation2, {
				background: "var(--color-surface-accent)",
				duration: 0,
			})
	}

	resetScrollAnimation() {
		if (ScrollTrigger.getById("introScrollAnimation"))
			ScrollTrigger.getById("introScrollAnimation").kill()

		gsap.killTweensOf(this.DOM.dotWrapper)

		this.scrollTimeline?.kill()
		gsap.set(this.DOM.dotWrapper, { clearProps: true })
	}

	resize() {
		this.resetScrollAnimation()
		this.initScrollAnimation()
	}
}
