import { Component } from "../abstracts/Component"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import SplitText from "gsap/SplitText"
import { wrapElements } from "../tools/DOM"

gsap.registerPlugin(ScrollTrigger, SplitText)

export class Values extends Component {
	constructor(config) {
		super(config)
		this.initCache()
		this.init()
	}

	initCache() {
		this.DOM.ourValuesHeading =
			this.DOM.root.querySelector(".values__values h3")
		this.DOM.ourValuesText = this.DOM.root.querySelector(".values__values p")
		this.theValues = ["value1", "value2", "value3", "value4"]
		this.DOM.valueSections = this.DOM.root.querySelector(".values__sections")
		this.DOM.valueSectionsChildren = this.DOM.root.querySelectorAll(
			".values__sections section"
		)
		this.DOM.valueSections.style.setProperty(
			"--_value-count",
			this.DOM.valueSectionsChildren.length
		)

		this.DOM.nextSection = this.DOM.root.nextElementSibling

		if (
			this.DOM.nextSection &&
			this.DOM.nextSection.tagName.toLowerCase() !== "section"
		) {
			this.DOM.nextSection = this.DOM.nextSection.querySelector("section")
		}
		this.DOM.dotSection = this.DOM.root.querySelector(".values__section-dot")
		this.DOM.dot = this.DOM.root.querySelector(".values__dot")
		this.DOM.valueTexts = this.DOM.root.querySelectorAll(".values__value-text")
		this.DOM.mission = this.DOM.root.querySelector(".values__mission")

		this.DOM.valueText = this.DOM.root.querySelectorAll(".values__value-text")
		this.DOM.valueSection = this.DOM.root.querySelectorAll(
			".values__section-value"
		)
		this.DOM.theValue = this.DOM.root.querySelectorAll(".values__value")

		this.valueTextsArray = []
		this.theValueArray = []
		this.scrollTriggers = []
		this.splitTextInstances = []
	}

	init() {
		this.dotAnimation()
		this.revealOurValues()
		this.revealValues()
	}

	dotAnimation() {
		ScrollTrigger.create({
			id: "dotAnimation",
			trigger: this.DOM.dotSection,
			scrub: true,
			//pin: this.DOM.dot,
			start: "top center",
			//endTrigger: this.DOM.root,
			end: `top+=${window.innerHeight * 5}`,
			toggleActions: "play reverse reverse play",
			//markers: true,
			animation: gsap
				.timeline()
				.fromTo(
					this.DOM.dot,
					{
						scale: 0,
					},
					{
						scale: 1,
						duration: 0.1,
					}
				)
				.to(this.DOM.dot, {
					scale: 1,
				})
				.to(this.DOM.dot, {
					scale: 0,
					duration: 0.1,
				}),
		})
	}

	revealValues() {
		this.DOM.valueTexts.forEach((text) => {
			this.DOM.valueTextsSplit = new SplitText(text, {
				type: "lines, words",
			})
			wrapElements(this.DOM.valueTextsSplit.lines, "div", "line-wrap")
			gsap.set(this.DOM.valueTextsSplit.lines, {
				yPercent: 100,
			})

			this.valueTextsArray.push(this.DOM.valueTextsSplit)

			this.splitTextInstances.push(this.DOM.valueTextsSplit)
		})

		this.DOM.theValue.forEach((text) => {
			this.DOM.theValueSplit = new SplitText(text, { type: "lines, chars" })
			wrapElements(this.DOM.theValueSplit.lines, "div", "heading-wrap")
			this.DOM.theValueSplit.chars.reverse()
			gsap.set(this.DOM.theValueSplit.chars, { yPercent: 100 })

			this.theValueArray.push(this.DOM.theValueSplit)

			this.splitTextInstances.push(this.DOM.theValueSplit)
		})

		this.tlRevealValues = gsap.timeline({
			scrollTrigger: {
				trigger: this.DOM.valueSections,
				pin: true,
				scrub: true,
				start: "top top",
				end: `+=${window.innerHeight * this.theValues.length}`,
				//markers: true,
				pinSpacing: true,
				toggleActions: "play pause play reset",
			},
		})

		// Set initial position for the first iteration only
		this.tlRevealValues.set(this.theValueArray[0].chars, {
			yPercent: 0,
		})

		for (let i = 0; i < 4; i++) {
			this.tlRevealValues

				.addLabel(`value${i}`)

				.to(this.valueTextsArray[i].lines, {
					yPercent: 0,
					stagger: 0.02,
				})
				.to(
					this.theValueArray[i].chars,
					{
						yPercent: 0,
						stagger: 0.1,
					},
					"<"
				)
				.to(this.valueTextsArray[i].lines, {
					yPercent: -100,
					delay: 0.3,
					stagger: 0.02,
				})
				.to(
					this.theValueArray[i].chars,
					{
						yPercent: -100,
						stagger: 0.1,
						duration: 0.6,
					},
					"<"
				)
		}
	}

	revealOurValues() {
		this.DOM.ourValuesTextSplit = new SplitText(this.DOM.ourValuesText, {
			type: "lines",
		})
		this.DOM.ourValuesHeadingSplit = new SplitText(this.DOM.ourValuesHeading, {
			type: "chars, lines",
		})
		this.splitTextInstances.push(this.DOM.ourValuesTextSplit)
		this.splitTextInstances.push(this.DOM.ourValuesHeadingSplit)
		wrapElements(this.DOM.ourValuesTextSplit.lines, "div", "line-wrap")
		wrapElements(this.DOM.ourValuesHeadingSplit.lines, "div", "line-wrap")
		this.tlOurValues = gsap.timeline()

		ScrollTrigger.create({
			id: "ourValuesAnimation",
			trigger: this.DOM.mission,
			start: "top center-=10%",
			toggleActions: "play none play reverse",
			//markers: true,
			animation: this.tlOurValues,
		})

		this.tlOurValues
			.fromTo(
				this.DOM.ourValuesTextSplit.lines,
				{
					y: 25,
				},
				{
					y: 0,
					stagger: 0.05,
					duration: 0.3,
				}
			)
			.fromTo(
				this.DOM.ourValuesHeadingSplit.chars,
				{
					y: 25,
				},
				{
					y: 0,
					stagger: 0.03,
					duration: 0.3,
				},
				"-=.15"
			)
	}

	resetScrollAnimations() {
		// Kill all ScrollTrigger instances associated with this component
		const scrollTriggerIds = ["dotAnimation", "ourValuesAnimation"]
		scrollTriggerIds.forEach((id) => {
			const trigger = ScrollTrigger.getById(id)
			if (trigger) trigger.kill()
		})

		// Kill and clear timelines
		const timelines = [this.tlRevealValues, this.tlOurValues]
		timelines.forEach((timeline) => {
			if (timeline) {
				if (timeline.scrollTrigger) timeline.scrollTrigger.kill()
				timeline.kill()
			}
		})
		this.tlRevealValues = null
		this.tlOurValues = null

		// Revert all SplitText instances and clear the array

		this.splitTextInstances.forEach((splitText) => {
			try {
				if (splitText && typeof splitText.revert === "function") {
					splitText.revert()
				}
			} catch (error) {
				console.warn("Error reverting SplitText:", error)
			}
		})
		this.splitTextInstances = []

		// Clear other arrays
		this.valueTextsArray = []
		this.theValueArray = []

		// Reset all relevant DOM elements to their original state
		const elementsToReset = [
			this.DOM.dot,
			...this.DOM.valueTexts,
			...this.DOM.theValue,
			this.DOM.ourValuesText,
			this.DOM.ourValuesHeading,
			...this.DOM.valueSectionsChildren,
		].filter(Boolean)

		gsap.set(elementsToReset, { clearProps: "all" })

		// Reset the custom property on valueSections
		if (this.DOM.valueSections) {
			this.DOM.valueSections.style.setProperty(
				"--_value-count",
				this.DOM.valueSectionsChildren.length
			)
		}
	}

	resize() {
		this.resetScrollAnimations()

		// Re-run initialization methods
		this.initCache()
		this.init()
	}
}
