import { componentsList } from "../components/index"
import { DEBUG_MODE } from "../config"
import { isMobile } from "../utils/devices"

export default class ComponentsManager {
	static instance

	constructor() {
		if (ComponentsManager.instance) {
			return ComponentsManager.instance
		}
		ComponentsManager.instance = this

		this.init()
		this.getComponents()
		this.mountComponents()

		this.resizeWindow()

		window.componenstManager = this
	}

	init() {
		this.componentsArray = []
	}

	getComponents(container = document) {
		this.components = container.querySelectorAll("[data-component]")
	}

	mountComponents() {
		this.components.forEach((component) => {
			const componentName = component.getAttribute("data-component")
			const ComponentClass = componentsList[componentName]

			if (ComponentClass) {
				try {
					const componentInstance = new ComponentClass(component)
					this.componentsArray.push(componentInstance)
				} catch (error) {
					console.error(`Error initializing component ${componentName}:`, error)
				}
			} else {
				console.warn(`Component ${componentName} not found in componentsList`)
			}
		})

		window.componentsArray = this.componentsArray

		if (DEBUG_MODE) {
			console.log("Components initialized:", this.componentsArray)
		}
	}

	unmountComponents() {
		this.componentsArrayStatic = []
		this.componentsArray.forEach((componentInstance) => {
			if (componentInstance.isStatic != true) {
				componentInstance.unmount()
				componentInstance = null
			} else {
				this.componentsArrayStatic.push(componentInstance)
			}
		})

		this.componentsArray = [...this.componentsArrayStatic]
	}

	resizeWindow() {
		const throttle = (func, limit) => {
			let inThrottle
			return function () {
				const args = arguments
				const context = this
				if (!inThrottle) {
					func.apply(context, args)
					inThrottle = true
					setTimeout(() => (inThrottle = false), limit)
				}
			}
		}

		const handleResize = () => {
			this.componentsArray.forEach((instance) => {
				if (typeof instance.resize === "function") {
					instance.resize()
				}
			})
		}

		if (!isMobile()) {
			window.addEventListener("resize", handleResize.bind(this))
		}
	}
}
