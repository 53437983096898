import { Component } from "../abstracts/Component"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { wrapElements } from "../tools/DOM"
import SplitText from "gsap/SplitText"
gsap.registerPlugin(ScrollTrigger, SplitText)

export class Footer extends Component {
	constructor(config) {
		super(config)
		this.splitTextInstances = []
		this.initCache()
		this.init()
	}

	initCache() {
		this.DOM.heading = this.DOM.root.querySelector(".footer__heading")
		this.DOM.subheadings = this.DOM.root.querySelectorAll(".footer__subheading")
		this.DOM.texts = this.DOM.root.querySelectorAll(".footer__text")
		this.DOM.icons = this.DOM.root.querySelectorAll(".footer__icon")
	}

	init() {
		this.initAnimation()
	}

	initAnimation() {
		this.DOM.headingSplit = new SplitText(this.DOM.heading, {
			type: "lines, chars",
		})
		this.splitTextInstances.push(this.DOM.headingSplit)
		wrapElements(this.DOM.headingSplit.lines, "div", "footer-wrap")

		this.DOM.subheadingsSplit = new SplitText(this.DOM.subheadings, {
			type: "chars",
		})
		this.splitTextInstances.push(this.DOM.subheadingsSplit)
		wrapElements(this.DOM.subheadingsSplit.chars, "span", "char-wrap")

		this.DOM.textsSplit = new SplitText(this.DOM.texts, { type: "lines" })
		this.splitTextInstances.push(this.DOM.textsSplit)
		wrapElements(this.DOM.textsSplit.lines, "div", "line-wrap")

		wrapElements(this.DOM.icons, "div", "line-wrap")

		gsap.set(this.DOM.headingSplit.chars, { yPercent: 100 })
		gsap.set(this.DOM.heading, { "--_dot-scale": 0 })
		gsap.set(this.DOM.subheadingsSplit.chars, { xPercent: -100 })
		gsap.set([this.DOM.textsSplit.lines, this.DOM.icons], { yPercent: -100 })

		this.animations()
	}

	animations() {
		this.tlFooter = gsap.timeline()

		this.scrollTrigger = ScrollTrigger.create({
			trigger: this.DOM.root,
			start: "top bottom-=200px",
			toggleActions: "play pause play reverse",
			animation: this.tlFooter,
		})

		this.tlFooter
			.to(this.DOM.headingSplit.chars, {
				yPercent: 0,
				stagger: 0.05,
				duration: 0.2,
			})
			.to(this.DOM.heading, {
				"--_dot-scale": 1,
			})
			.to(
				this.DOM.subheadingsSplit.chars,
				{
					xPercent: 0,
					stagger: 0.03,
					duration: 0.1,
				},
				"-=.5"
			)
			.to(
				[this.DOM.textsSplit.lines, this.DOM.icons],
				{
					yPercent: 0,
					stagger: 0.03,
					duration: 0.3,
				},
				"-=.3"
			)
	}

	resetScrollAnimations() {
		// Kill the ScrollTrigger instance
		if (this.scrollTrigger) {
			this.scrollTrigger.kill()
			this.scrollTrigger = null
		}

		// Kill the timeline
		if (this.tlFooter) {
			this.tlFooter.kill()
			this.tlFooter = null
		}

		// Revert all SplitText instances
		this.splitTextInstances.forEach((splitText) => {
			if (splitText && typeof splitText.revert === "function") {
				splitText.revert()
			}
		})
		this.splitTextInstances = []

		// Reset all relevant DOM elements to their original state
		const elementsToReset = [
			this.DOM.heading,
			...this.DOM.subheadings,
			...this.DOM.texts,
			...this.DOM.icons,
		].filter(Boolean)

		gsap.set(elementsToReset, { clearProps: "all" })

		// Clear split text references
		this.DOM.headingSplit = null
		this.DOM.subheadingsSplit = null
		this.DOM.textsSplit = null
	}

	resize() {
		this.resetScrollAnimations()
		this.initCache()
		this.init()
	}
}
